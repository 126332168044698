import { createApp } from 'vue'
import App from './App.vue'

createApp(App).mount('#app')
// import Model from './components/model.vue'
//
// function install (Vue, options) {
//   Vue.component('dt-model-engine', Model)
// }
//
// export default {
//   install
// }
