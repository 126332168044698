<template>
  <div class="model">
    <canvas id="model" style="display: block"></canvas>
    <canvas style="height: 30vw; width: 90%;position: fixed; z-index: -100;" id="heatmap0"></canvas>
    <canvas style="height: 30vw; width: 90%;position: fixed; z-index: -100;" id="heatmap1"></canvas>
    <canvas style="height: 30vw; width: 90%;position: fixed; z-index: -100;" id="heatmap2"></canvas>
    <canvas style="height: 30vw; width: 90%;position: fixed; z-index: -100;" id="greymap0"></canvas>
    <canvas style="height: 30vw; width: 90%;position: fixed; z-index: -100;" id="greymap1"></canvas>
    <canvas style="height: 30vw; width: 90%;position: fixed; z-index: -100;" id="greymap2"></canvas>
<!--    <button style="position: fixed;right: 0;top: 0;" @click="changeHeat(2)">high</button>-->
<!--    <button style="position: fixed;right: 100px;top: 0;" @click="changeHeat(1)">middle</button>-->
<!--    <button style="position: fixed;right: 200px;top: 0;" @click="changeHeat(0)">low</button>-->
<!--    <button style="position: fixed;right: 300px;top: 0;" @click="changeHeat(3)">恢复</button>-->
  </div>
</template>

<script setup>
import * as THREE from 'three'
import * as heat from 'heatmap.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js'
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js'
// import { GUI } from 'three/examples/jsm/libs/lil-gui.module.min.js'
import { onMounted, defineProps, onUnmounted, defineExpose } from 'vue'
import axios from 'axios'

const temperatureColorStops = {
  1.0: '#f00',
  0.9: '#e2fa00',
  0.6: '#33f900',
  0.3: '#0349df',
  0.0: '#0f00ff'
}

const props = defineProps({
  type: {
    type: String,
    default: 'normal'
  }
})

let allCamera = null

const pointLightList = []

let airLightPosition = [
  { x: 74, y: 45, z: 56.5 },
  { x: 48, y: 45, z: 56.5 },
  { x: 31, y: 45, z: 56.5 },
  { x: 15, y: 45, z: 56.5 },
  { x: -11, y: 45, z: 56.5 },
  { x: -37, y: 45, z: 56.5 },
  { x: -62, y: 45, z: 56.5 },
  { x: -88, y: 45, z: 56.5 },
  { x: -115, y: 45, z: 56.5 },
  { x: 74, y: 45, z: -46.7 },
  { x: 57, y: 45, z: -46.7 },
  { x: 31, y: 45, z: -46.7 },
  { x: 15, y: 45, z: -46.7 },
  { x: -11, y: 45, z: -46.7 },
  { x: -37, y: 45, z: -46.7 },
  { x: -62, y: 45, z: -46.7 },
  { x: -88, y: 45, z: -46.7 },
  { x: -115, y: 45, z: -46.7 }
]

const heatInfo = [
  [
    { x: 74, y: 18, z: 54, id: 28, type: 'low' },
    { x: -11, y: 18, z: 54, id: 16, type: 'low' },
    { x: -130, y: 18, z: 54, id: 1, type: 'low' },
    { x: -130, y: 18, z: 6, id: 2, type: 'low' },
    { x: -130, y: 18, z: -44, id: 3, type: 'low' },
    { x: -11, y: 18, z: -44, id: 17, type: 'low' },
    { x: 74, y: 18, z: -44, id: 30, type: 'low' },
    { x: 53, y: 18, z: 6, id: 29, type: 'low' },
    { x: 15, y: 18, z: 22, id: 19, type: 'low' },
    { x: -3, y: 18, z: -12, id: 18, type: 'low' },
    { x: -51, y: 18, z: 22, id: 4, type: 'low' },
    { x: -73, y: 18, z: -12, id: 5, type: 'low' }
  ],
  [
    { x: -11, y: 22, z: 54, id: 20, type: 'middle' },
    { x: -130, y: 22, z: 54, id: 6, type: 'middle' },
    { x: -130, y: 22, z: 6, id: 7, type: 'middle' },
    { x: -130, y: 22, z: -44, id: 8, type: 'middle' },
    { x: -11, y: 22, z: -44, id: 21, type: 'middle' },
    { x: 15, y: 22, z: 22, id: 23, type: 'middle' },
    { x: -3, y: 22, z: -12, id: 22, type: 'middle' },
    { x: -51, y: 22, z: 22, id: 9, type: 'middle' },
    { x: -73, y: 22, z: -12, id: 10, type: 'middle' }
  ],
  [
    { x: -11, y: 45, z: 54, id: 24, type: 'high' },
    { x: -130, y: 45, z: 54, id: 11, type: 'high' },
    { x: -130, y: 45, z: 6, id: 12, type: 'high' },
    { x: -130, y: 45, z: -44, id: 13, type: 'high' },
    { x: -11, y: 45, z: -44, id: 25, type: 'high' },
    { x: 15, y: 45, z: 22, id: 27, type: 'high' },
    { x: -3, y: 45, z: -12, id: 26, type: 'high' },
    { x: -51, y: 45, z: 22, id: 14, type: 'high' },
    { x: -73, y: 45, z: -12, id: 15, type: 'high' }
  ]
]

class NormalModel {
  composer = null
  constructor (camera, renderer) {
    const scene = new THREE.Scene()
    const loader = new GLTFLoader()
    loader.load('normal.glb', function (gltf) {
      gltf.scene.scale.set(500, 500, 500)
      scene.add(gltf.scene)
    }, undefined, function (error) {
      console.log(error)
    })
    const ambientLight = new THREE.AmbientLight('white', 3)
    scene.add(ambientLight)
    // for (const item of pointLightPosition) {
    //   const pointLight = new THREE.PointLight('green', 100, 3, 2)
    //   pointLight.position.set(item.x, item.y, item.z)
    //   pointLightList.push(pointLight)
    //   scene.add(pointLight)
    //   const helper = new THREE.PointLightHelper(pointLight)
    //   helper.scale.set(1, 1, 1)
    //   scene.add(helper)
    // }
    // scene.add(pointLight.target)

    // const helper = new THREE.PointLightHelper(pointLight)
    // helper.scale.set(1, 1, 1)
    // scene.add(helper)
    const renderScene = new RenderPass(scene, camera)
    this.composer = new EffectComposer(renderer)
    this.composer.addPass(renderScene)
  }

  render () {
    this.composer.render()
  }
}

class HeatModel {
  composer = null

  heatModel = [{}, {}, {}]

  heatTextModel = [
    [], [], []
  ]

  heatPointModel = [
    [], [], []
  ]

  circleTimer = null

  constructor (camera, renderer) {
    const scene = new THREE.Scene()
    const loader = new GLTFLoader()
    loader.load('heat.glb', function (gltf) {
      gltf.scene.scale.set(500, 500, 500)
      scene.add(gltf.scene)
    }, undefined, function (error) {
      console.log(error)
    })
    this.addPluginHeatmap(38, 2, scene)
    this.addPluginHeatmap(27, 1, scene)
    this.addPluginHeatmap(15, 0, scene)
    this.addPluginAir(scene)
    const renderScene = new RenderPass(scene, camera)
    this.composer = new EffectComposer(renderer)
    this.composer.addPass(renderScene)
    this.circleTimer = setInterval(() => {
      this.updateTemp()
    }, 60000)
    this.airTimer = setInterval(() => {
      this.updateAir()
    }, 60000)
  }

  render () {
    this.composer.render()
  }

  heatmapInstanceList = [{}, {}, {}]
  greymapInstanceList = [{}, {}, {}]

  // 创建热力图
  addPluginHeatmap (zIndex, index, scene) {
    // 创建一个heatmap实例对象
    // “h337” 是heatmap.js全局对象的名称.可以使用它来创建热点图实例
    // 这里直接指定热点图渲染的div了.heatmap支持自定义的样式方案,网页外包接活具体可看官网api
    this.heatmapInstanceList[index] = heat.create({

      container: document.getElementById(`heatmap${index}`),

      // backgroundColor:'red',    // '#121212'    'rgba(0,102,256,0.2)'
      gradient: temperatureColorStops,
      opacity: 0.5,
      blur: '.8'

    })

    this.greymapInstanceList[index] = heat.create({
      container: document.getElementById(`greymap${index}`),
      gradient: {
        0: 'black',
        '1.0': 'white'
      }
    })

    this.setHeatMapData(this.heatmapInstanceList[index], this.greymapInstanceList[index], index)

    // 获取 heatmap
    // const texture = new THREE.Texture( heatmapInstance._renderer.canvas );
    // const material = new THREE.MeshLambertMaterial( {
    //   map: texture,
    //   transparent: true,
    //   opacity: 1
    // });

    const heatMapMeshConfig = new THREE.PlaneGeometry(300, 106, 300, 300)
    const heatMapMeshMaterial = new THREE.ShaderMaterial({
      transparent: true,
      vertexShader: `varying vec2 vUv;
        uniform float Zscale;
        uniform sampler2D greyMap;
        void main() {
         vUv = uv;
        vec4 frgColor = texture2D(greyMap, uv);//获取灰度图点位信息
        float height = Zscale * frgColor.a;//通过灰度图的rgb*需要设置的高度计算出热力图每个点位最终在z轴高度
        vec3 transformed = vec3( position.x, position.y, height);//重新组装点坐标
        gl_Position = projectionMatrix * modelViewMatrix * vec4(transformed, 1.0);//渲染点位
        }
      `,
      fragmentShader: `varying vec2 vUv;
        uniform sampler2D heatMap;//热力图
        uniform vec3 u_color;//基础颜色
        uniform float u_opacity; // 透明度
        void main() {
           gl_FragColor = vec4(u_color, u_opacity) * texture2D(heatMap, vUv);//把热力图颜色和透明度进行渲染
        }`,
      uniforms: {
        heatMap: {
          value: { value: undefined }
        },
        greyMap: {
          value: { value: undefined }
        },
        Zscale: { value: 3.0 }, // 高度参数
        u_color: {
          value: new THREE.Color('rgb(255, 255, 255)')
        },
        u_opacity: {
          value: 1.0
        }
      }
    })
    const texture = new THREE.Texture(this.heatmapInstanceList[index]._config.container.children[0])
    texture.needsUpdate = true
    const texture2 = new THREE.Texture(this.greymapInstanceList[index]._config.container.children[0])
    texture2.needsUpdate = true
    heatMapMeshMaterial.uniforms.heatMap.value = texture
    heatMapMeshMaterial.side = THREE.DoubleSide // 双面渲染
    heatMapMeshMaterial.uniforms.greyMap.value = texture2
    const mesh = new THREE.Mesh(heatMapMeshConfig, heatMapMeshMaterial)

    // const mesh = new THREE.Mesh( new THREE.PlaneGeometry( 292, 106, 10 ), material );
    mesh.rotation.x = -Math.PI / 2
    mesh.scale.set(1, 1, 1)
    mesh.position.set(0, zIndex, 5)
    mesh.frustumCulled = false
    this.heatModel[index] = mesh
    scene.add(mesh)
    // 更新图片
    if (texture) {
      texture.needsUpdate = true
    }
    for (const item of heatInfo[index]) {
      this.getCanvasText(item, scene, index)
    }
  }

  getCanvasText (item, scene, index) {
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    canvas.width = 800
    canvas.height = 400
    ctx.clearRect(0, 0, canvas.width, canvas.height)

    ctx.fillStyle = 'rgba(255, 255, 2550, 0)'

    ctx.rect(0, 0, canvas.width, canvas.height)
    ctx.fill()

    ctx.font = 120 + 'px Arial'
    ctx.fillStyle = '#FFF'

    ctx.fillText(`温度：${item.temp}℃`, 100, 150)
    ctx.fillText(`湿度：${item.humidity}%`, 100, 300)
    const map = new THREE.CanvasTexture(canvas)
    map.wrapS = THREE.RepeatWrapping
    map.wrapT = THREE.RepeatWrapping

    const material = new THREE.SpriteMaterial({
      map: map,
      side: THREE.DoubleSide
    })
    const text = new THREE.Sprite(material)
    text.scale.set(10, 5)
    const CircleGeometry = new THREE.SphereGeometry(1, 32, 32)
    const CircleMaterial = new THREE.MeshBasicMaterial({ color: 0xffffff })
    const circle = new THREE.Mesh(CircleGeometry, CircleMaterial)
    text.position.set(item.x, item.y + 8, item.z - 4)
    circle.position.set(item.x, item.y, item.z)

    this.heatTextModel[index].push(text)
    this.heatPointModel[index].push(circle)

    text.visible = false
    circle.visible = false

    scene.add(text)
    scene.add(circle)
  }

  // 设置热力图位置温度数据
  setHeatMapData (heatmapInstance, greymapInstance, index) {
    const points = []
    let max = 0
    let min = 0
    // 随机位置点设置温度值
    for (const item of heatInfo[index]) {
      const val = item.temp
      const radius = 600
      max = 25
      min = 15
      const point = {
        x: (item.x + 140) * 8.5,
        y: (item.z + 50) * 7,
        value: val,
        radius: radius
      }
      points.push(point)
    }

    // 准备 heatmap 的数据
    const data = {
      max: max,
      min: min,
      data: points
    }
    // 因为data是一组数据,web切图报价所以直接setData
    heatmapInstance.setData(data) // 数据绑定还可以使用
    greymapInstance.setData(data)
  }

  changeHeat (type) {
    for (const item of this.heatModel) item.visible = false
    for (const item of this.heatTextModel) {
      for (const text of item) {
        text.visible = false
      }
    }
    for (const item of this.heatPointModel) {
      for (const text of item) {
        text.visible = false
      }
    }
    switch (type) {
      case 0:
        this.heatModel[0].visible = true
        for (const item of this.heatTextModel[0]) {
          item.visible = true
        }
        for (const item of this.heatPointModel[0]) {
          item.visible = true
        }
        break
      case 1:
        this.heatModel[1].visible = true
        for (const item of this.heatTextModel[1]) {
          item.visible = true
        }
        for (const item of this.heatPointModel[1]) {
          item.visible = true
        }
        break
      case 2:
        this.heatModel[2].visible = true
        for (const item of this.heatTextModel[2]) {
          item.visible = true
        }
        for (const item of this.heatPointModel[2]) {
          item.visible = true
        }
        break
      default:
        for (const item of this.heatModel) item.visible = true
    }
  }

  async updateTemp () {
    await getTempData()
    console.log('--temp update--')
    for (const a in this.heatTextModel) {
      for (const b in this.heatTextModel[a]) {
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')
        canvas.width = 800
        canvas.height = 400
        ctx.clearRect(0, 0, canvas.width, canvas.height)

        ctx.fillStyle = 'rgba(255, 255, 2550, 0)'

        ctx.rect(0, 0, canvas.width, canvas.height)
        ctx.fill()

        ctx.font = 120 + 'px Arial'
        ctx.fillStyle = '#FFF'

        ctx.fillText(`温度：${heatInfo[a][b].temp}℃`, 100, 150)
        ctx.fillText(`湿度：${heatInfo[a][b].humidity}%`, 100, 300)
        const map = new THREE.CanvasTexture(canvas)
        this.heatTextModel[a][b].material.map = map
      }
    }
    for (const i in heatInfo) {
      this.setHeatMapData(this.heatmapInstanceList[i], this.greymapInstanceList[i], i)
      const texture = new THREE.Texture(this.heatmapInstanceList[i]._config.container.children[0])
      texture.needsUpdate = true
      const texture2 = new THREE.Texture(this.greymapInstanceList[i]._config.container.children[0])
      texture2.needsUpdate = true
      this.heatModel[i].material.uniforms.heatMap.value = texture
      this.heatModel[i].material.side = THREE.DoubleSide // 双面渲染
      this.heatModel[i].material.uniforms.greyMap.value = texture2
    }
  }

  clearCircleTimer () {
    clearInterval(this.circleTimer)
  }

  airTimer = null

  circleMesh = []

  addPluginAir (scene) {
    const CircleGeometry = new THREE.SphereGeometry(1, 32, 32)
    for (const item of airLightPosition) {
      // 运行/待机/故障
      const CircleMaterial = new THREE.MeshBasicMaterial({ color: this.translateColor(item.value) })
      const circle = new THREE.Mesh(CircleGeometry, CircleMaterial)
      circle.position.set(item.x, item.y, item.z)
      this.circleMesh.push(circle)
      scene.add(circle)
    }
  }

  translateColor (value) {
    switch (value) {
      case '运行':
        return 'green'
      case '待机':
        return 'yellow'
      case '故障':
        return 'red'
      default:
        return 'white'
    }
  }

  async updateAir () {
    await getAirData()
    console.log('--air update--')
    for (const i in this.circleMesh) {
      this.circleMesh[i].material.color = new THREE.Color(this.translateColor(airLightPosition[i].value))
    }
  }

  clearAirTimer () {
    clearInterval(this.airTimer)
  }
}
class WindModel {
  composer = null
  constructor (camera, renderer) {
    const scene = new THREE.Scene()
    const loader = new GLTFLoader()
    loader.load('wind.glb', function (gltf) {
      gltf.scene.scale.set(500, 500, 500)
      scene.add(gltf.scene)
    }, undefined, function (error) {
      console.log(error)
    })
    const renderScene = new RenderPass(scene, camera)
    this.composer = new EffectComposer(renderer)
    this.composer.addPass(renderScene)
  }

  render () {
    this.composer.render()
  }
}

const resizeRendererToDisplaySize = (renderer) => {
  const canvas = renderer.domElement
  const width = canvas.clientWidth
  const height = canvas.clientHeight
  const needResize = canvas.width !== width || canvas.height !== height
  if (needResize) {
    renderer.setSize(width, height, false)
  }
  return needResize
}

let heatModelInstance = null
let normalModelInstance = null
let windModelInstance = null

onMounted(async () => {
  await getTempData()
  await getAirData()
  const camera = new THREE.PerspectiveCamera(40, window.innerWidth / window.innerHeight, 0.1, 5000)
  camera.position.set(280, 40, -1)
  camera.lookAt(0, 30, 0)

  allCamera = camera

  const canvas = document.getElementById('model')

  const renderer = new THREE.WebGLRenderer({ antialias: true, canvas, alpha: true })
  renderer.setSize(window.innerWidth, window.innerHeight)
  normalModelInstance = new NormalModel(camera, renderer)
  heatModelInstance = new HeatModel(camera, renderer)
  windModelInstance = new WindModel(camera, renderer)
  const controls = new OrbitControls(camera, canvas)
  // 限制摄像头不能翻转到z轴下
  // controls.maxPolarAngle = Math.PI / 2
  controls.update()
  const resize = () => {
    camera.aspect = window.innerWidth / window.innerHeight
    camera.updateProjectionMatrix()
    renderer.setSize(window.innerWidth, window.innerHeight)
  }
  window.addEventListener('resize', resize)
  const animate = () => {
    requestAnimationFrame(animate)

    if (resizeRendererToDisplaySize(renderer)) {
      const canvas = renderer.domElement
      camera.aspect = canvas.clientWidth / canvas.clientHeight
      camera.updateProjectionMatrix()
    }

    switch (props.type) {
      case 'normal':
        normalModelInstance.render()
        break
      case 'heat':
        heatModelInstance.render()
        break
      case 'wind':
        windModelInstance.render()
        break
    }

    // renderer.render(scene, camera)
  }
  animate()
})

onUnmounted(() => {
  normalModelInstance.clearCircleTimer()
})

const getTempData = async () => {
  const data = (await axios.get('/api/device/data/all')).data.data
  for (const item of heatInfo) {
    for (const pos of item) {
      pos.temp = data[pos.id - 1].temperature
      pos.humidity = data[pos.id - 1].humidity
    }
  }
}

const getAirData = async () => {
  const data = (await axios.get('/api/air-conditioner/all')).data.data
  const airPosition = airLightPosition.reverse()
  for (const i in data) {
    airPosition[i].value = data[i].status
  }
  airLightPosition = airPosition
}

const changeHeat = (type) => {
  heatModelInstance.changeHeat(type)
}

defineExpose({ changeHeat })
</script>

<style>
.model {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 2;
}
</style>
