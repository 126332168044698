<template>
  <div class="bg">
    <model ref="model" :type="modelType" />
    <div class="header">
      <div style="display: flex; padding-top: .8vw;">
        <div class="top-button-xl">
          <div>{{ day }}</div>
        </div>
        <div class="top-button-sm rotate">
          <div class="rotate">{{ week }}</div>
        </div>
        <div class="top-button-sm rotate">
          <div class="rotate">{{ time }}</div>
        </div>
      </div>
      <div class="title"></div>
      <div style="display: flex; padding-top: .8vw;">
        <div class="top-button-sm">{{ weather.windDirection }}风{{ weather.windPower }}级</div>
        <div class="top-button-sm">湿度 {{ weather.humidity }}%</div>
        <div class="top-button-xl rotate">
          <div class="rotate">{{ weather.weather }} {{ weather.temperature }}℃</div>
        </div>
      </div>
    </div>
<!--    <div class="top-button">-->
<!--      <div class="top-button-group">-->
<!--        <div class="warehouse" @click="changeModel('normal')"></div>-->
<!--        <div class="heatmap" @click="changeModel('heat')"></div>-->
<!--        <div class="wind" @click="changeModel('wind')"></div>-->
<!--      </div>-->
<!--    </div>-->
    <div class="bottom-button">
      <div class="top-button-group">
        <div class="warehouse-button" @click="changeModal(1)"></div>
        <div class="efficiency-button" @click="changeModal(2)"></div>
        <div class="energy-button" @click="changeModal(3)"></div>
      </div>
    </div>
    <div v-if="showModal && index === 1" class="modal-1"></div>
    <div v-if="showModal && index === 2" class="modal-2"></div>
    <div v-if="showModal && index === 3" class="modal-3"></div>
    <div v-if="modelType === 'heat' && !showModal" class="layer-3" @click="changeHeatLayer(2)"></div>
    <div v-if="modelType === 'heat' && !showModal" class="layer-2" @click="changeHeatLayer(1)"></div>
    <div v-if="modelType === 'heat' && !showModal" class="layer-1" @click="changeHeatLayer(0)"></div>
    <div v-if="modelType === 'heat' && !showModal" class="legend">
      <div class="legend-item">
        <div class="legend-circle green"></div>
        <div class="legend-text">运行</div>
      </div>
      <div class="legend-item">
        <div class="legend-circle yellow"></div>
        <div class="legend-text">待机</div>
      </div>
      <div class="legend-item">
        <div class="legend-circle red"></div>
        <div class="legend-text">故障</div>
      </div>
    </div>
    <div class="footer"></div>
  </div>
</template>

<script>
import Model from './model'
import moment from 'moment'
import axios from 'axios'
export default {
  components: { Model },
  name: 'dashboard',
  data () {
    return {
      showModal: false,
      index: 0,
      // 当前天
      day: moment().format('YYYY年MM月DD日'),
      // 当前周几
      week: '',
      // 当前时间
      time: moment().format('HH:mm:ss'),
      clockTimer: null,
      modelType: 'heat',
      weather: { humidity: '', temperature: '', windDirection: '', windPower: '', weather: '' },
      layerIndex: 3
    }
  },
  mounted () {
    this.getWeather()
    moment.locale('zh-cn')
    this.clockTimer = setInterval(() => {
      // 刷新天
      this.day = moment().format('YYYY年MM月DD日')
      // 刷新星期
      this.week = moment.weekdays(true)[moment().format('e')]
      // 刷新时间
      this.time = moment().format('HH:mm:ss')
    }, 1000)
    this.weatherClock = setInterval(this.getWeather, 3600000)
  },
  unmounted () {
    clearInterval(this.clockTimer)
  },
  methods: {
    changeModel (type) {
      this.showModal = false
      this.modelType = type
      if (this.modelType === 'heat') this.$refs.model.changeHeat(3)
    },
    changeModal (index) {
      if (this.index === index && this.showModal === true) {
        this.showModal = false
      } else {
        this.showModal = true
        this.index = index
      }
    },
    changeHeatLayer (index) {
      if (this.layerIndex === index) {
        this.$refs.model.changeHeat(3)
      } else {
        this.layerIndex = index
        this.$refs.model.changeHeat(index)
      }
    },
    async getWeather () {
      const data = (await axios.get('/api/weather')).data.data
      this.weather = data
    }
  }
}
</script>

<style scoped>
.bg {
  background-image: url("/assets/bg.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 100vh;
}
.header {
  background-image: url("/assets/title-bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100vw;
  height: 4vw;
  display: flex;
  justify-content: space-between;
  position: fixed;
  top: 0;
  z-index: 2;
  padding: 0 1vw;
}
.title {
  background-image: url("/assets/title.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100vw;
  height: 14vw;
  margin-top: -5.4vw;
  position: absolute;
  left: 0;
  right: 0;
}
.top-button {
  position: fixed;
  top: 5.5vw;
  width: 100vw;
  height: 10vw;
  z-index: 2;
}
.top-button-group {
  display: flex;
  justify-content: center;
  width: 50vw;
  margin: auto;
}
.warehouse {
  background-image: url("/assets/warehouse.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 9.5vw;
  height: 4.3vw;
  margin: 0 1vw;
  cursor: pointer;
}
.heatmap {
  background-image: url("/assets/heatmap.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 9.5vw;
  height: 4.3vw;
  margin: 0 1vw;
  cursor: pointer;
}
.wind {
  background-image: url("/assets/wind.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 9.5vw;
  height: 4.3vw;
  margin: 0 1vw;
  cursor: pointer;
}
.bottom-button {
  position: fixed;
  bottom: 4vw;
  width: 100vw;
  height: 10vw;
  z-index: 2;
}
.warehouse-button {
  background-image: url("/assets/warehouse-button.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 10.4vw;
  height: 8.85vw;
  margin: 0 1.2vw;
  cursor: pointer;
}
.efficiency-button {
  background-image: url("/assets/efficiency-button.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 10.4vw;
  height: 8.85vw;
  margin: 0 1.2vw;
  cursor: pointer;
}
.energy-button {
  background-image: url("/assets/energy-button.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 10.4vw;
  height: 8.85vw;
  margin: 0 1.2vw;
  cursor: pointer;
}
.footer {
  background-image: url("/assets/footer.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: fixed;
  bottom: 0;
  width: 100vw;
  height: 5.4vw;
  z-index: 2;
}
.top-button-xl {
  background-image: url("/assets/top-button-xl.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 2.6vw;
  width: 11vw;
  color: white;
  padding: .7vw 0;
  text-align: center;
  font-size: 1vw;
}
.top-button-sm {
  background-image: url("/assets/top-button-sm.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 2.6vw;
  width: 8.5vw;
  color: white;
  padding: .7vw 0;
  text-align: center;
  font-size: 1vw;
}
.rotate {
  transform: rotateY(180deg);
}
.modal-1 {
  background-image: url("/assets/modal-1.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 30vw;
  width: 50vw;
  position: fixed;
  top: 50%;
  left: 50%;
  transform:translate(-50%,-60%);
  z-index: 5;
}
.modal-2 {
  background-image: url("/assets/modal-2.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 36.3vw;
  width: 93vw;
  position: fixed;
  top: 50%;
  left: 50%;
  transform:translate(-50%,-60%);
  z-index: 5;
}
.modal-3 {
  background-image: url("/assets/modal-3.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 31.2vw;
  width: 93vw;
  position: fixed;
  top: 50%;
  left: 50%;
  transform:translate(-50%,-60%);
  z-index: 5;
}
.layer-1 {
  background-image: url("/assets/layer1.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 2.7vw;
  width: 14vw;
  position: fixed;
  top: 49%;
  right: 5%;
  z-index: 5;
  cursor: pointer;
}
.layer-2 {
  background-image: url("/assets/layer2.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 2.7vw;
  width: 14vw;
  position: fixed;
  top: 42%;
  right: 5%;
  z-index: 5;
  cursor: pointer;
}
.layer-3 {
  background-image: url("/assets/layer3.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 2.7vw;
  width: 14vw;
  position: fixed;
  top: 35%;
  right: 5%;
  z-index: 5;
  cursor: pointer;
}
.legend {
  top: 10%;
  right: 8%;
  z-index: 5;
  position: fixed;
}
.legend-item {
  display: flex;
  margin-bottom: 20px;
}
.legend-circle {
  border-radius: 50%;
  width: .8vw;
  height: .8vw;
  margin-right: .8vw
}
.green {
  background-color: green;
}
.yellow {
  background-color: yellow;
}
.red {
  background-color: red;
}
.legend-text {
  font-size: 20px;
  color: white;
  line-height: .8vw;
}
</style>
